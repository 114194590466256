import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_notify = _resolveComponent("van-notify");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_notify, {
    show: _ctx.show,
    "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.show = $event;
    }),
    type: _ctx.option.type,
    message: _ctx.option.message
  }, null, 8, ["show", "type", "message"]), _createVNode(_component_router_view)], 64);
}