import { __decorate, __extends } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { emitter } from "@/event";
var App = /** @class */function (_super) {
  __extends(App, _super);
  function App() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  App = __decorate([Options({
    created: function () {
      emitter.on('notify', this.notifyMessage);
    },
    methods: {
      notifyMessage: function (data) {
        var _this = this;
        this.option = data;
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.show = true;
        this.timer = setTimeout(function () {
          _this.show = false;
          _this.timer = null;
        }, 5 * 1000);
      }
    },
    data: function () {
      return {
        option: {
          type: null,
          message: null
        },
        show: false,
        timer: null
      };
    }
  })], App);
  return App;
}(Vue);
export default App;