import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    // {
    //     path: '/',
    //     name: 'home',
    //     component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    // },
    {
        path: '/',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
    },
    {
        path: '/unlock',
        name: 'unlock',
        component: () => import(/* webpackChunkName: "unlock" */ '@/views/Unlock.vue')
    },
    {
        path: '/quick',
        name: 'quick',
        component: () => import(/* webpackChunkName: "quick" */ '@/views/QuickLink.vue')
    },
    {
        path: '/vehicle',
        name: 'vehicle',
        component: () => import(/* webpackChunkName: "vehicle" */ '@/views/Vehicle.vue')
    },
    {
        path: '/walk',
        name: 'walk',
        component: () => import(/* webpackChunkName: "walk" */ '@/views/Walk.vue')
    }, {
        path: '/teams',
        name: 'teams',
        component: () => import(/* webpackChunkName: "walk" */ '@/views/Teams.vue')
    },
    {
        path: '/expired',
        name: 'expired',
        component: () => import(/* webpackChunkName: "exp" */ '@/views/ExpiredView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
